exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aromatherapie-js": () => import("./../../../src/pages/aromatherapie.js" /* webpackChunkName: "component---src-pages-aromatherapie-js" */),
  "component---src-pages-behandlungen-js": () => import("./../../../src/pages/behandlungen.js" /* webpackChunkName: "component---src-pages-behandlungen-js" */),
  "component---src-pages-body-spa-haende-js": () => import("./../../../src/pages/body-spa-haende.js" /* webpackChunkName: "component---src-pages-body-spa-haende-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-up-js": () => import("./../../../src/pages/make-up.js" /* webpackChunkName: "component---src-pages-make-up-js" */),
  "component---src-pages-waxing-js": () => import("./../../../src/pages/waxing.js" /* webpackChunkName: "component---src-pages-waxing-js" */)
}

